import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { ResultsLayout } from "../components/ResultsLayout"
import { useStaticQuery, graphql } from "gatsby"

const PersonalResultsPage = () => {
  const data = useStaticQuery(graphql`
    {
      allWpFineoptionrow(sort: {fields: menuOrder, order: ASC}) {
        nodes {
          title
          rowDetails {
            fineOptions {
              ... on WpFineoption {
                title
                link
                settings {
                  excerpt
                }
              }
            }
          }
        }
      }
    }
  `)

  return (

    <Layout results>
      <SEO title="Understanding all my options" />
        <ResultsLayout fineData={data.allWpFineoptionrow.nodes} />
    </Layout>
  )
}

export default PersonalResultsPage
